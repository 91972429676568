<template>
  <v-navigation-drawer v-model="drawer" hide-overlay dark app>
    <v-btn to="/" block class="pa-8">
      <v-img max-width="150" alt="Microwd Logo" src="@/assets/logos/logo.png" />
    </v-btn>

    <v-list>
      <div v-for="(item, i) in items" :key="i">
        <v-list-item v-if="!item.items && item.access_role.includes(user.role)" :to="item.route">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>

        <v-list-group v-if="item.items && item.access_role.includes(user.role)" v-model="item.active" :prepend-icon="item.icon">
          <template v-slot:activator>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </template>
          <v-list-item v-for="(child, i) in item.items" :key="i" :to="child.route" dense>
            <v-list-item-icon>
              <v-icon color="grey">subdirectory_arrow_right</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ child.title }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
      </div>
    </v-list>

    <template v-slot:append>
      <p id="version_text" class="text-center">{{ version }}</p>
      <v-btn block @click="logout()">Cerrar sesión</v-btn>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      // TODO: Cuando se lance la app quitar el permiso a los TECHNICIAN en toda la web
      version: "",
      items: [
        { title: "Administradores", icon: "admin_panel_settings", route: "/admins", access_role: ["SUPERADMIN"] },
        {
          title: "Inversores",
          icon: "account_balance_wallet",
          access_role: ["SUPERADMIN", "ADMIN"],
          items: [
            { title: "Retail", route: "/inversores/retail", access_role: ["SUPERADMIN", "ADMIN"] },
            { title: "Fondo", route: "/inversores/fund", access_role: ["SUPERADMIN", "ADMIN"] },
            { title: "Reembolsos", route: "/reembolsos", access_role: ["SUPERADMIN", "ADMIN"] }
          ]
        },
        { title: "Técnicos", icon: "build", route: "/tecnicos", access_role: ["SUPERADMIN", "ADMIN", "SUPERTECHNICIAN"] },
        {
          title: "Prestatarias",
          icon: "nature_people",
          route: "/prestatarias",
          access_role: ["SUPERADMIN", "ADMIN", "SUPERTECHNICIAN", "TECHNICIAN"]
        },
        { title: "Partners", icon: "group_work", route: "/partners", access_role: ["SUPERADMIN", "ADMIN", "SUPERTECHNICIAN"] },
        { title: "Oficinas", icon: "apartment", route: "/oficinas", access_role: ["SUPERADMIN", "ADMIN", "SUPERTECHNICIAN"] },
        { title: "Proyectos", icon: "folder", route: "/proyectos", access_role: ["SUPERADMIN", "ADMIN", "TECHNICIAN", "SUPERTECHNICIAN"] },
        { title: "Bolsas", icon: "folder_copy", route: "/bolsas", access_role: ["SUPERADMIN", "ADMIN"] },
        {
          title: "Solicitudes",
          icon: "assignment",
          route: "/solicitudes",
          access_role: ["SUPERADMIN", "ADMIN", "TECHNICIAN", "SUPERTECHNICIAN"]
        },
        { title: "Localizaciones", icon: "map", route: "/localizaciones", access_role: ["SUPERADMIN", "ADMIN", "SUPERTECHNICIAN"] },
        { title: "Productos", icon: "monetization_on", route: "/productos", access_role: ["SUPERADMIN", "ADMIN", "SUPERTECHNICIAN"] },
        {
          title: "Envíos",
          icon: "send",
          access_role: ["SUPERADMIN", "ADMIN"],
          items: [
            { title: "Crear envío", route: "/crear-envio", access_role: ["SUPERADMIN"] },
            { title: "Historial", route: "/envios", access_role: ["SUPERADMIN", "ADMIN"] }
          ]
        },
        { title: "Ajustes generales", icon: "settings", route: "/ajustes", access_role: ["SUPERADMIN", "ADMIN"] }
      ],
      itemsTest: [
        {
          items: [{ title: "List Item" }],
          title: "Attractions"
        },
        {
          title: "Administradores",
          icon: "admin_panel_settings",
          route: "/admins",
          access_role: ["SUPERADMIN"]
        },
        {
          items: [{ title: "List Item" }],
          title: "Education"
        }
      ]
    };
  },
  computed: {
    ...mapState("user", ["user"]),
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(value) {
        this.$store.commit("SET_DRAWER", value);
      }
    }
  },
  created() {
    this.checkChildsActives();
  },
  mounted() {
    this.version = process.env.VUE_APP_VERSION;
  },
  methods: {
    async logout() {
      try {
        await this.$store.dispatch("authentication/logout");
        this.$router.replace({ name: "Login" });
      } catch (error) {
        console.log(error);
      }
    },

    checkChildsActives() {
      for (let i = 0; i < this.items.length; i++) {
        const link = this.items[i];

        if (link.items) {
          const someChildActive = this.items[i].items.some((e) => e.route === this.$route.path);
          if (someChildActive) link.active = true;
        }
      }
    }
  }
};
</script>

<style scoped>
#version_text {
  color: #b9b9b9;
}

::v-deep .v-list-item {
  max-height: 48px;
}

/* ::v-deep .v-list-item__icon {
  margin-left: 0 !important;
  margin-right: 0 !important;
} */
</style>
